import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "assessment-result-modal" }
const _hoisted_2 = {
  class: "modal fade",
  ref: "assessmentResultDialog",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_3 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_4 = { class: "modal-content" }
const _hoisted_5 = { class: "modal-results-header" }
const _hoisted_6 = { class: "assessment-tag assessment-tag--irla" }
const _hoisted_7 = {
  key: 0,
  class: "result-details"
}
const _hoisted_8 = { class: "result-date" }
const _hoisted_9 = { class: "modal-body" }
const _hoisted_10 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "#modal-container" }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("button", {
                class: "btn-close",
                "data-bs-dismiss": "modal",
                "aria-label": "Close",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.closeDialog && $options.closeDialog(...args)))
              }),
              _createElementVNode("header", null, [
                _createElementVNode("h3", null, _toDisplayString(_ctx.$t('pages.assessment.assessment')) + " " + _toDisplayString($data.index), 1),
                _createElementVNode("span", _hoisted_6, _toDisplayString($data.result.type), 1)
              ]),
              ($data.result.grade)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t('pages.assessment.grade')) + " " + _toDisplayString($data.result.grade), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_8, _toDisplayString($options.formattedDate) + " (" + _toDisplayString($options.timeAgo) + ") ", 1)
            ]),
            _createElementVNode("div", _hoisted_9, [
              ($options.assessmentLevel($data.result))
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: `https://arccontentstore.blob.core.windows.net/assessment/images/assessment-results-${$options.assessmentLevel($data.result).toLowerCase()}.jpg`,
                    alt: `Assessment Results Image for Level ${$options.assessmentLevel($data.result)}`
                  }, null, 8, _hoisted_10))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ], 512)
    ])
  ]))
}