
import {defineComponent} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import eventBus from "@/core/services/eventBus";
import HelpModal from "@/app/components/modals/HelpModal.vue";
import AssessmentResult from "@/app/components/AssessmentResult.vue";
import AssessmentResultModal from "@/app/components/modals/AssessmentResultModal.vue";
import authenticationService from "@/core/services/AuthenticationService";
// import assessmentService from "@/app/services/assessmentService";
import arcReadsApiClient from "@/app/services/arcReadsApiClient";
import assessmentApiClient from "@/app/services/assessmentApiClient";

export default defineComponent({
  name: "assessment",
  components: {AssessmentResultModal, AssessmentResult, HelpModal},
  beforeUnmount() {
    eventBus.$off('openHelp');
  },
  data() {
    return {
      assessmentStatusLoading: true,
      assessmentStatus: undefined,
      level_assessments: undefined
    }
  },
  created() {
    arcReadsApiClient.get('/myirla').then(response => {
      this.level_assessments = response.data;
    });
    
    assessmentApiClient.get(`/assessment-status/student-instance/${authenticationService.currentUser().currentInstance.studentInstanceId}`).then(response => {
      if (response.data.data?.progressId) {
        this.assessmentStatus = response.data.data.progressId;
      }
      else if (response.data.data === null) this.assessmentStatus = null
      else {
        this.assessmentStatus = null
      }
    })
    .catch((error) => {
      console.error(error);
      this.assessmentStatus = null
    });
  },
  computed: {
    allAssessments() {
      let assessments:any = [];
      let lastSession = null;
      if (this.level_assessments && this.level_assessments.length) {
        this.level_assessments.forEach(a => {
          if (authenticationService.currentUser().currentInstance.grade.abbreviation) a.grade = authenticationService.currentUser().currentInstance.grade.abbreviation.replace(/\D/g,'');
          if (a.sessionId != lastSession && a.conclusive) {
            assessments.push(a);
            lastSession = a.sessionId;
          }
        });
      }

      // TODO: Ascertain why this initial filter needed to exist, then clean this area up
      // Filter out initial 1G assessment - not sure why this is there, seems to be a bug in assessments app
      // return assessments.filter(x=>x.readingLevel != '1G');
      return assessments.filter(x=>x.complete === 1);
    },
    assessmentUrl() {
      return process.env.VUE_APP_ARCID_HOST + '/authorize?response_type=code&client_id=' + process.env.VUE_APP_ARCASSESSMENT_OAUTH_CLIENT_ID + '&redirect_uri=' + process.env.VUE_APP_ARCASSESSMENT_WEB_HOST + '/oauth/arcid'
    },
    irla() {
      return this.allAssessments.filter((result) => result.type === 'irla');
    },
    enil() {
      return this.allAssessments.filter((result) => result.type === 'enil');
    },
  },
  methods: {
    openResults(result, index) {
      this.$refs.assessmentResultModal.openDialog(result, index);
    },
    playVideo($event) {
      let video;
      if ($event.target.tagName !== 'VIDEO') {
        const tip = $event.target.closest('.tip');
        video = tip.querySelector('video');
      }
      else {
        video = $event.target;
      }
      const shroud = video.closest('.video-shroud');
      shroud.classList.add('playing');
      return new Promise(function (resolve, reject) {
        video.onerror = reject;
        video.onended = function(e: Event) {
          shroud.classList.remove('playing');
          resolve(e);
        };
        video.play();
      });
    }
  },
  mounted() {
    setCurrentPageTitle("My IRLA");
    eventBus.$on('openHelp', () => {
      this.$refs.helpModal.openDialog();
    });
  }
});
