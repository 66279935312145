
import { Modal } from "bootstrap";
import moment from 'moment-with-locales-es6';
import assessmentService from "@/app/services/assessmentService";

export default {
  data() {
    return {
      index: null,
      result: {
        id: null,
        teacher: null,
        grade: null,
        class: null,
        date: null,
        timeago: null,
        level: 'Bk',
        type: null
      }
    }
  },
  methods: {
    openDialog(result, index) {
      this.result = result;
      this.index = index;
      this.dialog = new Modal(this.$refs.assessmentResultDialog);
      this.dialog.show();
    },
    closeDialog() {
      if (this.dialog)
        this.dialog.hide();
    },
    assessmentLevel(assessment) {
      return assessmentService.getReadingLevelForAssessment(assessment);
    }
  },
  computed: {
    formattedDate() {
      return moment.utc(this.result.created_at).format('ll');
    },
    timeAgo() {
      return moment.utc(this.result.created_at).fromNow();
    }
  }
}
