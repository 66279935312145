const assessmentService: any = {
    readingLevels: ['3Y', '1G', '2G', '1B', '2B', '1R', '2R', 'Wt', 'Bk', 'Or', 'Pu', '1Br', '2Br', 'Si', 'Gl'],

    /**
     * Returns the reading level one level below the given assessment - because the assessment is always the last
     * level assessed, if we assessed up to 2R then we want to show "suggested starting place: 1R" (one level below)
     *
     * @param assessment
     */
    getReadingLevelForAssessment(assessment) {
        // Whatever the highest level assessed is, that is the level where the student failed to move on to the next one.
        // So we want to show the reading level *before* the highest level assessed...
        //
        // Unless that level is Gold. In that case, if assessment.passed == 1 then they did complete it with a passing
        // score, so we don't back down a level in that specific case.
        // let levelAdjustment = (assessment.passed == 1 && assessment.readingLevel == 'Gl') ? 0 : 1;
        let levelAdjustment = 0
        if (assessment.passed === 0) levelAdjustment = 1

        return this.readingLevels[this.readingLevels.indexOf(assessment.readingLevel) - levelAdjustment];
    }
}

export default assessmentService;