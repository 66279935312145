
import moment from 'moment-with-locales-es6';
import assessmentService from "@/app/services/assessmentService";

export default {
  props: ['result', 'index'],
  data() {
    return {}
  },
  computed: {
    formattedDate() {
      return moment.utc(this.result.created_at).format('ll');
    },
    timeAgo() {
      return moment.utc(this.result.created_at).fromNow();
    }
  },
  methods: {
    assessmentLevel(assessment) {
      return assessmentService.getReadingLevelForAssessment(assessment);
    }
  }
};
