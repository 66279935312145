import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-result"]
const _hoisted_2 = { class: "assessment-result__aside" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "assessment-result__main" }
const _hoisted_5 = {
  key: 0,
  class: "result-details"
}
const _hoisted_6 = { class: "result-date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "assessment-result",
    "data-result": $options.assessmentLevel($props.result).toLowerCase()
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: `https://arccontentstore.blob.core.windows.net/assessment/images/assessment-results-${$options.assessmentLevel($props.result).toLowerCase()}.jpg`,
        alt: `Assessment Results Image for Level ${$options.assessmentLevel($props.result)}`
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("header", null, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.$t('pages.assessment.assessment')) + " " + _toDisplayString($props.index), 1)
      ]),
      ($props.result.grade)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('pages.assessment.grade')) + " " + _toDisplayString($props.result.grade), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, _toDisplayString($options.formattedDate) + " (" + _toDisplayString($options.timeAgo) + ") ", 1)
    ])
  ], 8, _hoisted_1))
}